<template>
  <div>
    <template v-if="item.children !== null">
      <v-list-group
        :prepend-icon="item.icon"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </template>
        <v-list-item
          v-for="(subMenu, i) in item.children"
          class="ml-5"
          :key="i"
          link
          :to="subMenu.to"
          dense
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon>{{ subMenu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ subMenu.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </template>
    <template v-else>
      <v-list-item link :to="item.to" target="_blank">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
